import React, { ReactNode } from 'react';
import '../index.css';

interface GlobalProps {
  children: ReactNode;
}

const Global:React.FC<GlobalProps> = ({ children }) => <div>{children}</div>;

export default Global;
