import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import Agency from '../images/agency.png';
import { TypographyLarge } from '../components/Typography';
import Button from '../components/Button';

const Container = styled.div`
  max-width: 640px;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: right;
  margin-left: auto;
  margin-right: 128px;
  @media(max-width: 800px){
    margin-right: 24px;
    margin-left: 24px;
    padding-top: 64px;
    padding-bottom: 64px;
    text-align: center;
    max-width: none;
  }
`;

const ContentWrapper = styled.div`

`;

const ProgressAndChangeSection = () => (
  <Section imgUrl={Agency}>
    <Container>
      <ContentWrapper>
        <TypographyLarge>
          Apoiando quem carrega o progresso e a mudança por todo o Brasil
        </TypographyLarge>
        <Button href="mailto:parceiro@dieselbank.com.br">
          Entre em contato
        </Button>
      </ContentWrapper>
    </Container>
  </Section>
);

export default ProgressAndChangeSection;
