import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import Trucks from '../images/trucks.png';
import { TypographyLarge } from '../components/Typography';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 640px;
  margin-left: 128px;
  @media(max-width: 800px){
    margin-right: 24px;
    margin-left: 24px;
    padding-top: 64px;
    padding-bottom: 64px;
    text-align: center;
    max-width: none;
  }
`;

const ContentWrapper = styled.div`
`;

const AbusiveTaxesSection = () => (
  <Section imgUrl={Trucks}>
    <Container>
      <ContentWrapper>
        <TypographyLarge>
          Deixando para trás a burocracia e as taxas
          abusivas do cartão de frete e de bancos tradicionais.
        </TypographyLarge>
      </ContentWrapper>
    </Container>
  </Section>
);

export default AbusiveTaxesSection;
