import * as React from 'react';
import { PageProps } from 'gatsby';
import Seo from '../components/seo';
import GlobalFonts from '../fonts/fonts';
import Navbar from '../components/Navbar';
import Global from '../components/Global';
import Footer from '../components/Footer';

import ProgressAndChangeSection from '../sections/ProgressAndChangeSection';
import AbusiveTaxesSection from '../sections/AbusiveTaxesSection';
import PartnersSection from '../components/PartnersSection';

const DieselBankPage:React.FC<PageProps> = ({ location }) => (
  <Global>
    <Seo />
    <GlobalFonts />
    <Navbar location={location} />
    <ProgressAndChangeSection />
    <AbusiveTaxesSection />
    <PartnersSection />
    <Footer />
  </Global>
);

export default DieselBankPage;
